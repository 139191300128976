import "magnific-popup";
import $ from "jquery"

document.addEventListener("turbolinks:load", function() {
  if ($('.gallery').length != 0) {
    $('.gallery').magnificPopup({
      delegate: 'a',
      type:'image',
      gallery: { enabled: true },
      removalDelay: 300,
      mainClass: 'mfp-with-zoom', // this class is for CSS animation below
      zoom: {
        enabled: true,
        duration: 300,
        easing: 'ease-in-out',
        opener: function(openerElement) {
          return openerElement.is('img') ? openerElement : openerElement.find('img');
        }
      }
    });
  }
});
